import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = 'https://docs.paperlessparts.com/v2'
  }, []);
  return null;
}

export default App;
